jQuery(document).ready(function($) {

  // Filter button for shop sidebar
  // $(".woocommerce .archive-topbar").append(
  //   "<div id='sidebar-toggle'>Categories</div>"
  // );

  // $(".woocommerce main.content .widget-area").prepend(
  //   "<div id='sidebar-toggle-close'><i class='icon icon-ic_close_48px'></i></div>"
  // );

  // $("#sidebar-toggle").click(function() {
  //   $(".woocommerce main.content .widget-area").show(300);
  // });

  // $("#sidebar-toggle-close").click(function() {
  //   $(".woocommerce main.content .widget-area").hide(300);
  // });

  /* Interactive Cart */
  $('#interactive-cart-activate, .interactive-cart-close, .interactive-cart__bg').click(interactiveCartToggle);

  function interactiveCartToggle() {
    $('#interactive-cart-wrapper').fadeToggle(300);
  }

  $('#interactive-cart-wrapper .remove_from_cart_button').click(function() {
    $(this).parent('.cart-item-card').remove();
  });

  /* Woocommerce Single Page */
  $(".flex-control-thumbs").slick({
    autoplay: false,
    vertical: true,
    slidesToShow: 5,
    speed: 300,
    focusOnSelect: true,
    verticalSwiping: true,
    responsive: [{
      breakpoint: 960,
      settings: "unslick"
    }]
  });

  $('.slick-arrow').click(function() {
    setTimeout(function() {
      $('.slick-current img').trigger("click");
    }, 50);
  });

  $('.slick-slide img').click(function(e) {
    if ($(e.target).parent().parent().parent().hasClass('slick-cloned')) {
      setTimeout(function() {
        $('.slick-current img').trigger("click");
      }, 50);
    }
  });


  /* Woocommerce search */
  $("#woocommerce-product-search-button i").click(function (e) {
    // position search form based on header height
    var headerHeight = $(".site-header").outerHeight();
    $("#woocommerce-product-search-field").css("top", headerHeight).val("");

    if (
      $(".woo-search-button").prop("disabled") === true &&
      $("#woocommerce-product-search-field").val().length > 0 &&
      $(this).parent().parent().hasClass("woocommerce-product-search--active")
    ) {
      $(".woo-search-button").prop("disabled", false);
    } else {
      $(".woo-search-button").prop("disabled", true);
    }

    /* This should be last */
    // if clicked on icon, activate
    if (e.target.nodeName === "I") {
      $(this)
        .parent()
        .parent()
        .toggleClass("woocommerce-product-search--active");
      $("#woocommerce-product-search-field").focus();
    }
  });

  $('body').click(function(e) {
    if ($('.woocommerce-product-search').hasClass('woocommerce-product-search--active')) {
      // making sure that only search components are clicked on
      if (e.target.id !== 'woocommerce-product-search-field' && e.target.parentNode.id !== 'woocommerce-product-search-button') {
        $('.woocommerce-product-search').removeClass('woocommerce-product-search--active');
      }
    }
  });

  $('#woocommerce-product-search-field').on('keydown', function(e) {
    if (e.which === 13 && $(this).val().length > 1) {
      $('.woocommerce-product-search').submit();
    }

    if (e.key === "Escape") {
      $(this).val('');
      $('.woocommerce-product-search').toggleClass('woocommerce-product-search--active');
    }
  });
});
